<template>
<div class="messages">
      <form class="search-haed" action="javascript:void 0" @submit.prevent="query">
            <div class="search">
                <div class="iconfont iconsearch"></div>
                <input  type="search" placeholder="请输入用户昵称" v-model="keyword" />
                <div class="chongzhi" @click="setReset()" v-if="listshow" >重置</div>
            </div>
        </form>
      <div class="list-box" v-for="(item,index) in list" :key="index"  >
          <SwipeCell slot="left" :right-width="80" >
            <Tag round color="#f00" style="position: absolute;z-index: 2;left: 1.1rem;top: 4px;" v-if="item.read_count">{{item.read_count}}</Tag>
              <Cell :border="false" class="bit-box" @click.stop="goPages('/Tasting/message/customer/'+item.to_uid)">
                  <div class="pic" >
                      <img :src="item.userinfo.avatar" mode="aspectFill" />
                  </div>
                  <div class="con">
                      <div class="tit ">{{item.userinfo.nickname}}</div>
                      <div class="content Ellipses1">{{item.last}}</div>
                  </div>
                  <div class="caozuo">{{item.time}}</div>
                  <div class="caozuo1" v-if="item.userinfo.store_name">店面：{{item.userinfo.store_name}}</div>
              </Cell>
              <div slot="right" class="bit-box">
                  <div class="del-bit" @click.stop="del(item.id,index)">删除</div>
              </div>
          </SwipeCell>
          <div class="line"></div>
      </div>
      <div class="Loading" v-if="loading">
          <Loading size="24px">加载中...</Loading>
      </div>
      <div class="Loading" v-if="finished">没有更多了~</div>
</div>
</template>
<script>
import { messageList,delMessage } from "@api/tasting";
import { Loading,Cell,List,SwipeCell,Tag,Toast} from 'vant';
  export default {
  name: 'messages',
  components: {
    Loading,Cell,List,SwipeCell,Tag,Toast
  },
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      keyword:'',
    };
  },
  mounted: function() {
      this.Loadmore();
      var that = this;
      window.addEventListener("scroll", function(event) {
                var scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
                if(document.documentElement.scrollHeight == document.documentElement.clientHeight + scrollTop) {
                    that.Loadmore();
                }
            });
  },
  methods: {
    goPages: function(url) {
            this.$router.push({ path: url });
        },
    // onLoad() {
    //   this.loading = true;
    //   // 异步更新数据
    //   // setTimeout 仅做示例，真实场景中一般为 ajax 请求
    //   setTimeout(() => {
    //     for (let i = 0; i < 12; i++) {
    //       this.list.push(this.list.length + 1);
    //     }

    //     // 加载状态结束
    //     this.loading = false;
    //     // 数据全部加载完成
    //     if (this.list.length >= 40) {
    //       //console.log(123);
    //       this.finished = true;
    //     }
    //   }, 1000);
    // },
    query(){
        return this.$dialog.toast({ mes: "正在努力开发中，敬请期待！" });
    },
    //删除
    del(itemid,index){
        delMessage({
            id:itemid,
        }).then(res=>{
            if(res.status==200){
                Toast.success({
                message: res.msg,
                forbidClick: true,
                });
                this.list.splice(index, 1);
                this.$set(this, "list",  this.list);
            }else{
                Toast.fail({
                message: res.msg,
                forbidClick: true,
            });
            }

        }).catch(err=>{
            Toast.fail({
                message: err.msg,
                forbidClick: true,
            });
        })
    },
    Loadmore: function() {
        if(this.loading) return;
        if(this.finished) return;
        this.messageList();
    },
    messageList:function(){
        this.loading = true;
        messageList({
            page:this.page,
            limit:this.limit,
            //keyword:this.keyword,
        }).then(res => {
            var listdata = res.data.data;
            listdata.forEach((item)=>{
                this.list.push(item);
            })
            this.loading = false;
            this.page++;
            this.list_lengths += listdata.length;
            if(listdata.length<12){
                this.finished = true;
            }
        }).catch(err => {
            this.loading = false;
            Toast.fail({
                message: err.msg,
                forbidClick: true,
            });
        });
    },
  }
}
</script>
<style lang="scss">
.Loading{text-align: center;padding:0.2rem;font-size:0.28rem;color:#999;}
.messages .search-haed{
        width: 100%;
        height: 1.3rem;
        //box-shadow:1px 1px 1px #000;
        background-color: #fff;
        .search{
            margin: 0 auto;
            width: 90%;padding: 0.4rem 0;
            input{
                width: 100%;
                padding: 0.1rem 0.6rem;
                padding-right:0.8rem ;
                border: 1px solid #ccc;
                border-radius: 0.3rem;
                //background-color: #ccc;
            }
            .iconfont{
                position: absolute;
                top: 0.5rem;
                left:7%;
            }
            .chongzhi{
                position: absolute;
                top: 0.5rem;
                right: 7%;
                z-index: 2;
            }
        }
    }
.messages .list-box{
  
  .bit-box{
         display: flex;
         height: 100%;
         //align-items:center;
         //justify-content:center;
         text-align: center;
         flex-wrap: nowrap;
        div{
            //flex-direction:row;
            //justify-content:space-evenly;
            //width: 30%;
        }
        .del-bit{
            height:100% ;
            background-color: rgb(231, 53, 53);
            width: 80px;
            color: #fff;
            font-weight: 600;
            line-height: 1.36rem;
        }
        .pic{float: left;width: 1rem;height: 1rem;margin-right: 0.5rem;border-radius: 0.1rem;overflow: hidden;}
        .pic img{width: 100%;height: 100%;}
        .con{float: left;width: 50%;}
        .con .tit{font-weight: 600;} 
        .con .content{color: #999;} 
        .caozuo{
        //writing-mode:vertical-lr;
            position: absolute;
            //padding-top: 0.1rem;
            right: 0rem;
            font-size: 0.2rem;
            color: #ccc;
        }
        .caozuo1{
            position: absolute;
            right: 0rem;
            font-size: 0.2rem;
            color: #f00;
            bottom: 0;
        }
  }
  .line{
    border-bottom: 1px solid #eee;
    margin-left: 1.5rem;
  }
  .van-swipe-cell__wrapper{
    transition: width 1s cubic-bezier(0.48, 0.43, 0.29, 1.3);
    transition-property: width;
    transition-duration: 1s;
    transition-timing-function: cubic-bezier(0.48, 0.43, 0.29, 1.3);
    transition-delay: 0s;
  }
}
</style>